import { type Component, Show, For, createEffect } from "solid-js";
import { A, type AccessorWithLatest } from "@solidjs/router";
import { Colour } from "./Colour";
import { Accordion } from "~/components/ui";
import { pathMapping } from "~/utils/products";
import { Product } from "~/services/roma-api/products/types";
import { useSiteContext } from "~/utils/contexts";

type ProductColourSelectorProps = {
  product: AccessorWithLatest<Product | undefined>;
  type: string;
};

type dummyType = {
  currentColour: string;
  availableList: Array<{
    SKU: string;
    Colour: string;
    ColourDescription: string;
  }>;
  options: { colours: Record<string, [string, boolean]> };
  baseColour: string;
  type: string;
};
export const ProductColourSelector: Component<ProductColourSelectorProps> = (
  props
) => {
  const { global } = useSiteContext();
  const colour = () => props.product()?.Colour ?? "";

  return (
    <Show when={props.product() && global()}>
      <div class="grid grid-cols-product-headers items-start text-sm">
        <p class="self-start font-bold">Colour</p>

        <Show
          when={
            props.product()?.AvailableList &&
            props.product()!.AvailableList!.length > 0
          }
          fallback={
            <div class="flex items-center gap-3 font-medium text-sm">
              <Show when={global().colours[colour()]}>
                <Colour
                  colour={global().colours[colour()][0]}
                  metallic={global().colours[colour()][1]}
                />
              </Show>
              <p>{props.product()?.ColourDescription}</p>
            </div>
          }
        >
          <Accordion
            name="colours"
            label={
              <div class="flex items-center gap-3 font-medium text-sm">
                <Show when={global().colours[colour()]}>
                  <Colour
                    colour={global().colours[colour()][0]}
                    metallic={global().colours[colour()][1]}
                  />
                </Show>
                <p>{props.product()?.ColourDescription}</p>
              </div>
            }
            icon="Chevron"
            iconRotation="180"
            childClass="text-sm"
          >
            <ul>
              <For each={props.product()?.AvailableList}>
                {(item) => (
                  <li>
                    <A
                      href={`/product/${item.SKU}/${
                        pathMapping[props.type] ?? ""
                      }`}
                      class="flex items-center gap-3 py-2 hover:text-roma-blue cursor-pointer transition"
                      classList={{
                        hidden:
                          item.ColourDescription ===
                          props.product()?.ColourDescription,
                      }}
                    >
                      <Show when={global().colours[item.Colour]}>
                        <Colour
                          colour={global().colours[item.Colour][0]}
                          metallic={global().colours[item.Colour][1]}
                          current={
                            item.ColourDescription ===
                            props.product()?.ColourDescription
                          }
                        />
                      </Show>
                      <p
                        class="font-medium"
                        classList={{
                          "text-roma-blue":
                            item.ColourDescription ===
                            props.product()?.ColourDescription,
                        }}
                      >
                        {item.ColourDescription}
                      </p>
                    </A>
                  </li>
                )}
              </For>
            </ul>
          </Accordion>
        </Show>
      </div>
    </Show>
  );
};
